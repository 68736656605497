
export default {
  props: {
    level: {
      type: String,
      default: "",
    },
    avatar: {
      type: String,
      default: "",
    },
    gender: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    color: "#4CD4FF",
    lvl: 1,
  }),
};
