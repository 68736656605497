
export default {
  components: {
    questionDetailAction: () => import('@/components/ask-question/questionDetailAction.vue')
  },
  props: {
    id: {
      type: [String, Number],
      default: ''
    },
    data: {
      type: Array,
      default: () => []
    },
    answer: {
      type: [String, Number],
      default: '0'
    }
  },
  data: () => ({
    loading: false,
    inputComment: '',
    inputAnswer: [],
    answer_show: null
  }),
  methods: {
    async submitComment (id) {
      // const commentLv1 = 'question/answer/post' + this.$route.params.slug
      // const commentLv2 = 'news/subcomment/create'
      // const url = subcomment ? commentLv2 : commentLv1
      // this.loading = true
      const formData = new FormData()
      formData.append('id_user', this.$store.state.auth.user.myProfile.id)
      formData.append('id_question', id)
      formData.append('answer', this.inputComment)
      // formData.append('comment', subcomment || this.inputComment)
      // if (id) {
      //   formData.append('comment_id', id)
      // }
      // const payload = {
      //   formData
      // }
      await this.$store.dispatch('public/submitCommentQuestion', formData)
        .then(() => {
          this.$parent.fetchDetail()
          this.loading = false
          this.answer_show = null
          this.inputComment = ''
          this.$message({
            message: 'Komentar berhasil terkirim',
            type: 'success'
          })
        })
        .catch((err) => {
          this.loading = false
          this.$message({
            message: 'Komentar gagal terkirim',
            type: 'error'
          })
          throw new Error(err)
        })
    },
    async deleteComment (id) {
      this.loading = true
      const formData = new FormData()
      formData.append('comment_id', id)
      await this.$store.dispatch('news/deleteCommentNews', formData)
        .then(() => {
          this.$parent.fetchDetail()
          this.loading = false
          this.$message({
            message: 'Hapus komentar berhasil',
            type: 'success'
          })
        })
        .catch((err) => {
          this.loading = false
          this.$message({
            message: 'Hapus komentar gagal',
            type: 'error'
          })
          throw new Error(err)
        })
    },
    async likeComment (id) {
      if (!this.$store.state.auth.user.isLogin) {
        this.$goTo('login')
      } else {
        if (!id) { return }
        // this.loading = true
        const formData = new FormData()
        formData.append('id_answer', id)
        await this.$store.dispatch('public/submitQnaLike', formData)
          .then(() => {
            this.$message({
              message: 'Berhasil menambahkan like',
              type: 'success'
            })
            this.$parent.fetchDetail()
            // this.loading = false
          })
          .catch(() => {
            // this.loading = false
            this.$message({
              message: 'Gagal menambahkan like ini',
              type: 'error'
            })
          })
      }
    }
  }
}
