
export default {
  props: {
    id: {
      type: [String, Number],
      default: ''
    }
  },
  data: () => ({
    loading: false
  }),
  methods: {
    handleShare (val) {
      if (!window) { return }
      const base = window.location.host
      const dateNow = new Date().getTime()
      const username = this.$store.state.auth.user.myProfile.slug ? this.$store.state.auth.user.myProfile.slug : '_' + Math.floor((Math.random() * 10000) + 1)
      const url = `https://${base}/mb/${this.$route.params.slug}?slug=${username}&_utm=${dateNow}`
      switch (val) {
        case 'fb' : return window.open('http://www.facebook.com/sharer/sharer.php?u=' + url + '&_ch=facebook', '_blank')
        case 'wa' : return window.open('https://api.whatsapp.com/send?text=' + url + '&_ch=whatsapp', '_blank')
        case 'tw' : return window.open('https://twitter.com/share?url=' + url + '&_ch=twitter', '_blank')
        case 'in' : return window.open('http://www.linkedin.com/shareArticle?mini=true&url=' + url + '&_ch=linkedin', '_blank')
      }
    },
    async submitQnaLike () {
      if (!this.$store.state.auth.user.isLogin) {
        this.$goTo('login')
      } else {
        if (!this.id) { return }
        this.loading = true
        const formData = new FormData()
        formData.append('id_question', this.id)
        await this.$store.dispatch('public/submitQnaLike', formData)
          .then(() => {
            this.$message({
              message: 'Berhasil menambahkan like',
              type: 'success'
            })
            this.$parent.$parent.fetchDetail()
            this.loading = false
          })
          .catch(() => {
            this.loading = false
            this.$message({
              message: 'Gagal menambahkan like ini',
              type: 'error'
            })
          })
      }
    }
  }
}
