
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    lists: {
      type: Array,
      default: () => [],
    },
    timeout: {
      type: Number,
      default: 2000,
    },
  },
  methods: {
    goPost(slug) {
      window.open(process.env.BASE_POST + "mb/" + slug, "_top");
    },
  },
};
